import React from "react";
import { Form, InputGroup } from "react-bootstrap";
import useAuth from "../hooks/useAuth";

//comment

const AdminLoginForm = () => {
  const {
    setAdminEmail,
    setPassword,
    submitAdminLogin,
    error,
    allAdminFieldsFilled,
    isLoggingIn,
  } = useAuth();

  return (
    <div className="admin-form">
      <h2 className="title">Admin login</h2>
      <br />
      <Form onSubmit={submitAdminLogin}>
        <Form.Group className="mb-3" controlId="formBasicEmail">
          <Form.Label>Email address</Form.Label>
          <InputGroup className="mb-3">
            <Form.Control
              type="email"
              placeholder="Enter email"
              aria-label="Recipient's username"
              onChange={(e) => setAdminEmail(e.target.value)}
            />
          </InputGroup>
          <Form.Label>Password</Form.Label>
          <InputGroup className="mb-3">
            <Form.Control
              type="password"
              placeholder="Enter password"
              aria-label="Recipient's username"
              aria-describedby="basic-addon2"
              onChange={(e) => setPassword(e.target.value)}
            />
          </InputGroup>
        </Form.Group>

        {error && <p className="error-message">{error}</p>}

        <button
          className={`join-btn ${allAdminFieldsFilled() ? "black" : "grey"}`}
          type="submit"
          disabled={!allAdminFieldsFilled()}
        >
          {isLoggingIn ? (
            <img
              className="check-mark"
              src="check.gif"
              alt="check"
              style={{ width: "22px" }}
            />
          ) : (
            "Login"
          )}
        </button>
      </Form>
    </div>
  );
};

export default AdminLoginForm;
