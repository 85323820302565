import React from "react";
import "../styles/Home.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "react-multi-carousel/lib/styles.css";
import { Carousel } from "react-bootstrap";
import AdminLoginForm from "../components/AdminLoginForm";

const Admin = (props) => {
  return (
    <div className="App">
      <div className="main">
        <div className="row">
          <div className="col-lg-6 left">
            <div className="form-container">
              <div className="form">
                <div className="brand-icon-div">
                  <img
                    src="https://mingdocs.s3.ca-central-1.amazonaws.com/brand-icon.png"
                    alt="brand-icon"
                    className="brand-icon"
                  />
                </div>
                <AdminLoginForm />
              </div>
            </div>
          </div>
          <div className="col-lg-6 right">
            <Carousel data-bs-theme="dark">
              <Carousel.Item>
                <img
                  className="d-block slide-img"
                  src="https://mingdocs.s3.ca-central-1.amazonaws.com/left_optimized.png"
                  alt="First slide"
                />
              </Carousel.Item>
              <Carousel.Item>
                <img
                  className="d-block slide-img"
                  src="https://mingdocs.s3.ca-central-1.amazonaws.com/user-left.png"
                  alt="Second slide"
                />
              </Carousel.Item>
              <Carousel.Item>
                <img
                  className="d-block slide-img"
                  src="https://mingdocs.s3.ca-central-1.amazonaws.com/offer-left.png"
                  alt="Third slide"
                />
              </Carousel.Item>
            </Carousel>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Admin;
