import React, { useEffect, useState } from "react";
import "../styles/Home.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "react-multi-carousel/lib/styles.css";
import UserRegistrationForm from "../components/UserRegistrationForm";
import AOS from "aos";
import "aos/dist/aos.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInstagram, faTiktok } from "@fortawesome/free-brands-svg-icons";

const Home = () => {
  const [hidden, setHidden] = useState(true);
  const [step, setStep] = useState(1);

  useEffect(() => {
    AOS.init({
      duration: 1000,
      easing: "ease-in-out",
      once: true,
    });

    setTimeout(() => {
      setHidden(false);
    }, 1500);
  }, []);

  const refreshPage = () => {
    window.location.reload();
  };

  return (
    <div className="App">
      <div className={`${step === 3 ? "blur-background" : ""}`}>
        <div
          className={`brand-icon-div ${step === 3 ? "hidden" : ""}`}
          style={{ textAlign: "center" }}
        >
          <img
            src="https://mingdocs.s3.ca-central-1.amazonaws.com/brand-icon.png"
            alt="brand-icon"
            className="brand-icon"
            style={{ width: "200px" }}
          />
        </div>
        <div className="main">
          <div
            data-aos="fade-up"
            className={`background-bag step${step}`}
          ></div>
          <div className="main-container">
            <span className="slogan">
              Don't <i>Sell</i> Your Bags,
            </span>{" "}
            <br />
            <span className="slogan">
              <i>Trade</i> Them for Another!
            </span>
            {!hidden && step !== 3 && (
              <div data-aos="fade-up">
                <UserRegistrationForm setStep={setStep} step={step} />
              </div>
            )}
          </div>
        </div>
      </div>
      {step === 3 && (
        <div>
          <div className="brand-icon-div" style={{ textAlign: "center" }}>
            <img
              src="https://mingdocs.s3.ca-central-1.amazonaws.com/brand-icon.png"
              alt="brand-icon"
              className="brand-icon"
              style={{ width: "200px" }}
            />
          </div>

          <div className="outter-form-container">
            <div className="form-finish">
              <h1>Welcome to the club</h1>
              <p>
                You’ve unlocked access to something extraordinary. Stay excited!
                We’ll be sending you sneak peeks and exclusive offers soon.
              </p>

              <div className="soical-media-div">
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://www.instagram.com/hyffinapp?igsh=MW5hYnZ5NTU0NWo4dA=="
                >
                  <FontAwesomeIcon
                    className="soical-media-icon"
                    icon={faInstagram}
                    color="white"
                    size="2xl"
                  />
                </a>

                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://www.tiktok.com/@hyffinapp"
                >
                  <FontAwesomeIcon
                    className="soical-media-icon"
                    icon={faTiktok}
                    color="white"
                    size="2xl"
                  />
                </a>
              </div>

              <button className={`join-btn white`} onClick={refreshPage}>
                Finish
              </button>

              <img
                src="https://mingdocs.s3.ca-central-1.amazonaws.com/hyffin-white.png"
                alt="hyffin-white"
                className="brand-icon"
                style={{ width: "30%" }}
              />
            </div>
            <br />

            <p>Peer to peer trading marketplace. You trade, we authenticate.</p>
          </div>
        </div>
      )}
    </div>
  );
};

export default Home;
